<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-light top-navbar"
            id="topbar-content"
        >
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="#" class="navbar-brand">Dashboard</a>
                        </li>
                        <li
                            class="breadcrumb-item active d-flex justify-content-center align-items-center"
                            aria-current="page"
                        >
                            Settings
                        </li>
                    </ol>
                </nav>
            </div>
        </nav>

        <div class="container">
            <div class="header">
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">Security</h6>
                            <h1 class="header-title">Settings</h1>
                        </div>
                        <div class="col-auto"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 order-md-2">
                    <!-- Card -->
                    <div class="card bg-light border ml-md-4">
                        <div class="card-body">
                            <p class="mb-2">{{ $t("Password suggestions") }}</p>
                            <p class="small text-muted mb-2">
                                {{
                                    $t(
                                        "To create a new strong password, you have to meet all of the following requirements:"
                                    )
                                }}
                            </p>

                            <ul class="small text-muted pl-4 mb-0">
                                <li>{{ $t("Minimum 8 character") }}</li>
                                <li>
                                    {{
                                        $t(
                                            "At least one special character (#%.,)"
                                        )
                                    }}
                                </li>
                                <li>{{ $t("At least one number") }}</li>
                                <li>
                                    {{
                                        $t(
                                            "Can’t be the same as a previous password"
                                        )
                                    }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <form @submit.prevent>
                        <div class="form-group">
                            <label>{{ $t("Password") }}</label>
                            <password-viewable
                                v-model="data.oldPassword"
                                :state="validation.oldPassword"
                                :error-message="errors.oldPassword"
                                :placeholder="$t('Current password')"
                            ></password-viewable>
                        </div>
                        <div class="form-group">
                            <label>{{ $t("New password") }}</label>
                            <password-viewable
                                v-model="data.newPassword"
                                :state="validation.newPassword"
                                :error-message="errors.newPassword"
                                :placeholder="$t('New password')"
                            ></password-viewable>
                        </div>
                         <div class="form-group">
                            <label>{{ $t("Confirm new password") }}</label>
                            <password-viewable
                                v-model="data.confirmPassword"
                                :state="validation.confirmPassword"
                                :error-message="errors.confirmPassword"
                                :placeholder="$t('Confirm new password')"
                            ></password-viewable>
                        </div>
                        <button-loading
                            type="submit"
                            :loading="loading"
                            @click="updatePassword"
                            class="btn btn-primary"
                            >{{ $t("Update password") }}
                        </button-loading>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import PasswordViewable from "@/components/PasswordViewable.vue";
import ButtonLoading from "@/components/ButtonLoading";

export default {
    components: {
        PasswordViewable,
        ButtonLoading,
    },
    data() {
        return {
            data: { oldPassword: "", newPassword: "", confirmPassword: "" },
            validation: {
                oldPassword: null,
                newPassword: null,
                confirmPassword: null,
            },
            errors: { oldPassword: "", newPassword: "", confirmPassword: "" },
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("authManagement", { loading: "isCreatePending" }),
        ...mapGetters("users", {
            getUserById: "get",
        }),
    },
    created() {
        this.getUser(this.user._id);
    },
    methods: {
        ...mapActions("users", {
            getUser: "get",
        }),
        ...mapActions("authManagement", {
            changePassword: "create",
        }),

        updatePassword() {
            if (this.data.newPassword !== this.data.confirmPassword) {
              this.$toasted.global.error({
                message: this.$t("Passwords do not match"),
              });
            } else {

            this.changePassword([{
                    action: "passwordChange",
                    value: {
                        user: {
                            email: this.user.email,
                        },
                        oldPassword: this.data.oldPassword,
                        password: this.data.newPassword,
                    }
                }])
                .then(() => {
                    this.$toasted.global.success({
                        message: this.$t("Password has been changed"),
                    });
                    this.data = {};
                    this.errors = {};
                    this.validation = {};
                })
                .catch((error) => {
                    console.log(error);
                    this.$toasted.global.error({
                        message: this.$t(error.message),
                    });
                });
            }


        },
    },
};
</script>

<style>
</style>
